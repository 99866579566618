import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { NavSide } from '../../components'
import Calculator from './calculator'
import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';
import './styles.scss'

class CalculationTankVolume extends Component {

    render() {
        return (
            <div className='calculation-hydraulic-sizing-tool page-bg'>
                <div className='container'>

                    <div className='content'>

                        <div className='top-content'>
                            <div className='breadcrumbs'>
                                <Link to='/specialized-materials-list' className='link-back'><span className='opacity'>Специализирани материали</span></Link>
                                <span className='opacity'>Калкулатор за Резервоари</span>
                            </div>
                        </div>

                        <div className='main-content'>
                            <div className='scroll'>
                                <PerfectScrollbar>
                                    <h3>Калкулатор за Резервоари</h3>
                                    <Calculator/>
                                </PerfectScrollbar>
                            </div>
                        </div>

                    </div>
                </div>
                <NavSide />
            </div>
        )
    }

}

export default connect()(CalculationTankVolume)