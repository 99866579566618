import React, { Component } from 'react'
import { connect } from 'react-redux'
// import {
//   CSSTransition,
//   TransitionGroup
// } from 'react-transition-group'
// import { ConnectedRouter } from 'react-router-redux'
import { Header } from '../../components'
import { Route, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'
// import PrivateRoute from './PrivateRoute'
// import {history} from '../../config/stores'
// import { routes } from '../../config/routes'
import {
  Home, AboutUs,
  SpecializedMaterials, SpecializedMaterialsList,
  CalculationHydraulicSizingTool, CalculationLinearDrainage, CalculationWallThickness,
  Contacts, Solutions, References, Reference, SolutionsList, Solution, CalculationTankVolume
} from '../../screens'
import ScrollToTop from '../ScrollToTop/ScrollToTop';

// Preferences,
class Routes extends Component {
  render () {
    return (
      <div className='wrapall' id="wrapall">
        {/* <TransitionGroup>
          <CSSTransition classNames='fade' enter leave timeout={1000}> */}
        <ScrollToTop />
        <Header />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/about-us' component={AboutUs} />
          <Route path='/solutions/:tab' component={Solutions} />
          <Route path='/solutions-list/:name' component={SolutionsList} />
          <Route path='/solution/:id' component={Solution} />
          <Route path='/specialized-materials' component={SpecializedMaterials} />
          <Route path='/specialized-materials-list' component={SpecializedMaterialsList} />
          <Route path='/calculation-hydraulic-sizing-tool' component={CalculationHydraulicSizingTool} />
          <Route path='/calculation-linear-drainage' component={CalculationLinearDrainage} />
          <Route path='/calculation-wall-thickness' component={CalculationWallThickness} />
          <Route path='/calculation-tank-volume' component={CalculationTankVolume} />
          <Route path='/references' component={References} />
          <Route path='/reference/:name' component={Reference} />
          <Route path='/contacts' component={Contacts} />
        </Switch>
        {/* </CSSTransition>
        </TransitionGroup> */}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { loading: state.starter.loading }
}

export default withRouter(connect(mapStateToProps)(Routes))
