import { StarterTypes } from '../actions'

const initialState = {

  dataCategories: [
    {
      id: 'urban-infrastructure-parkinglots',
      categoryName: 'Градска инфраструктура - Паркинги'
    },
    {
      id: 'urban-infrastructure-pedestrian-areas',
      categoryName: 'Градска инфраструктура - Пешеходни зони'
    },
    {
      id: 'urban-infrastructure-streets',
      categoryName: 'Градска инфраструктура - Улици'
    },
    {
      id: 'urban-infrastructure-parks-and-gardens',
      categoryName: 'Градска инфраструктура - Паркове и градини'
    },
    {
      id: 'transport-infrastructure-highway',
      categoryName: 'Транспортна инфраструктура - Магистрали'
    },
    {
      id: 'transport-infrastructure-tunnel',
      categoryName: 'Транспортна инфраструктура - Тунели'
    },
    {
      id: 'transport-infrastructure-bridge',
      categoryName: 'Транспортна инфраструктура - Мостове'
    },
    {
      id: 'transport-infrastructure-roads',
      categoryName: 'Транспортна инфраструктура - Пътища'
    },
    {
      id: 'industrial-and-logistics-areas-dock',
      categoryName: 'Индустриални и логически зони - Пристанища'
    },
    {
      id: 'industrial-and-logistics-areas-airport',
      categoryName: 'Индустриални и логически зони - Летища'
    },
    {
      id: 'housing-construction-housing-cooperatives',
      categoryName: 'Жилищно строителство - Жилищни кооперации'
    },
    {
      id: 'housing-construction-one-family-houses',
      categoryName: 'Жилищно строителство - Еднофамилни къщи'
    },
    {
      id: 'shopping-areas-supermarkets',
      categoryName: 'Търговски зони - Супермаркети'
    },
    {
      id: 'shopping-areas-malls-and-shopping-centers',
      categoryName: 'Търговски зони - Молове и търговски центрове'
    },
    {
      id: 'shopping-areas-gas-stations-and-car-service',
      categoryName: 'Търговски зони - Бензиностанции и автосервизи'
    },
  ],

  data: [

    {
      id: 'hydrooil-bypass',
      pdf: false,
      title: 'HYDROOIL BYPASS',
      categories: ['urban-infrastructure-parkinglots', 'transport-infrastructure-highway', 'transport-infrastructure-bridge', 'transport-infrastructure-tunnel', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock'],
      categoriesNames: ['Градска инфраструктура - Паркинги', 'Транспортна инфраструктура - Паркинги'],
      shortdescription: 'HYDROOIL BYPASS се изработва от спирално навити полиетиленови тръби и заварени екструдирани плочи.',
      application: 'HYDROOIL BYPASS се използва за пречистване на дъждовна и отпадъчна вода, замърсена с петролни продукти, мазнини, масла, пясък, прах, кал и други. Този каломаслоуловител е подходящ за пречистване на големи количества течности. Той е създаден така, че да поема и пречиства определено количество течности, но и в случай на по-голям непредвиден поток от вода тя да преминава през байпасното устройство.',
      description: 'HYDROOIL BYPASS се изработва от спирално навити полиетиленови тръби и заварени екструдирани плочи.',
      quality: 'HYDROOIL BYPASS се изработва спрямо стандарт БДС EN 858. Съоръжението е изпитано притежава необходимите документи гарантиращи неговото качество.',
      efficiency: 'Системата е базирана на физични закони от механиката на флуидите и хидродинамиката. Отличен коалесцентен филтър – финно пречистване чрез еотекстилна обвивка и полиетиленова мрежа. Дефлектор намаляващ динамичните въздействия върху съоръжението и спомагащ ефективното утаяване на твърдите частици (пясък, кал и др.).',
      installation: '1) Прави се изкоп за монтиране на съответният каломаслоуловител. Да се спазва естественият откос на почвата при дълбочина до 3.5 м, а при по-голяма дълбочина да се укрепи изкопът. Ако почвата е слаба трябва да се усили по съответните методи. При високи подпочвени води и опасност от изплуване трябва да се вземат необходимите мерки (отводняване на изкопа, дренаж, укрепване на съоръжението срещу изплуване и други ) и да се направят необходимите изчисления. 2) Дъното на изкопът се уплътнява много добре. 3) Изпълнява се пясъчна възглавница ( добре уплътнена ) или бетонова подложка в зависимост от големината на съоръжението и натоварването. 4) Съоръжението се полага в изкопа. 5) Изкопът се запълва като се уплътнява на няколко етапа по 40-50 см. Използва се почва с подходяща зърнометрия или едра фракция пясък. Да се работи внимателно при комбинация от едра фракция пясък и смесена фракция чакъл, за да не се причинят повреди на съоръжението. При монтажът на съоръженията трябва да се спазват изискванията на стандарт БДС EN 1610.',
      techinfo: ['/data/hydrooil-bypass/techinfo/HydroOil_Bypass_NS-6-30-ls-SF-1200.pdf', '/data/hydrooil-bypass/techinfo/HydroOil_Bypass_NS-6-50-ls-SF-1500.pdf', '/data/hydrooil-bypass/techinfo/HydroOil_Bypass_NS 8-80-ls-SF-800.pdf', '/data/hydrooil-bypass/techinfo/HydroOil_Bypass_NS-10-100-ls-SF-1000.pdf', '/data/hydrooil-bypass/techinfo/HydroOil_Bypass_NS-15-75-ls-SF-1700.pdf', '/data/hydrooil-bypass/techinfo/HydroOil_Bypass_NS-20-200-ls-SF-4000.pdf', '/data/hydrooil-bypass/techinfo/HydroOil_Bypass_NS-30-300-ls-SF-3000.pdf', '/data/hydrooil-bypass/techinfo/HydroOil_Bypass_NS-40-400-ls-SF-3000.pdf', '/data/hydrooil-bypass/techinfo/HydroOil_Bypass_NS-6-30-ls-SF-1200-1.pdf'],
      drawings: ['/data/hydrooil-bypass/drawings/HDROOIL-BYPASS-NS-6-30ls-SF1200.pdf', '/data/hydrooil-bypass/drawings/HDROOIL-BYPASS-NS-6-50ls-SF1500.pdf', '/data/hydrooil-bypass/drawings/HDROOIL-BYPASS-NS-8-80ls-SF800.pdf', '/data/hydrooil-bypass/drawings/HDROOIL-BYPASS-NS-10-100ls-SF1000.pdf', '/data/hydrooil-bypass/drawings/HDROOIL-BYPASS-NS-10-100ls-SF1000(NADSTOIKA).pdf', '/data/hydrooil-bypass/drawings/HDROOIL-BYPASS-NS-15-75ls-SF1700.pdf', '/data/hydrooil-bypass/drawings/HDROOIL-BYPASS-NS-20-200ls-SF4000.pdf', '/data/hydrooil-bypass/drawings/HDROOIL-BYPASS-NS-30-300ls-SF3000.pdf', '/data/hydrooil-bypass/drawings/HDROOIL-BYPASS-NS-40-240ls-SF3000.pdf'],
      images: ['/data/hydrooil-bypass/HYDROOIL-BYPASS-1.2.new.png', '/data/hydrooil-bypass/HYDROOIL-BYPASS-1.2new.png', '/data/hydrooil-bypass/HYDROOIL-BYPASS-NS-6-30-1.1.png', '/data/hydrooil-bypass/HYDROOIL-BYPASS-NS-6-30-1.2.png', '/data/hydrooil-bypass/HYDROOIL-BYPASS-NS-6-30-1.3.png', '/data/hydrooil-bypass/HYDROOIL-BYPASS-NS-6-30-1.4.png', '/data/hydrooil-bypass/HYDROOIL-BYPASS-NS-6-30-1.5.png', '/data/hydrooil-bypass/HYDROOIL-BYPASS-NS-6-30-1.6.png']
    },
    {
      id: 'hydrooil-duo',
      pdf: false,
      title: 'HYDROOIL DUO',
      categories: ['urban-infrastructure-parkinglots', 'transport-infrastructure-tunnel', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock'],
      categoriesNames: ['Паркинги - КМУ'],
      shortdescription: ['Използва се за пречистване на дъждовна и отпадъчна вода, замърсена с петролни продукти, мазнини, масла, пясък, прах, кал и други.'],
      application: 'HYDROOIL DUO се използва за пречистване на дъждовна и отпадъчна вода, замърсена с петролни продукти, мазнини, масла, пясък, прах, кал и други. Този каломаслоуловител е подходящ за пречистване на много големи количества течности.',
      description: 'Този каломаслоуловител е подходящ за пречистване на много големи количества течности.',
      quality: 'HYDROOIL BYPASS е изработен и отговаря на експлоатационните характеристики спрямо стандарт БДС EN 858. Съоръжението е изпитано притежава необходимите документи гарантиращи неговото качество.',
      efficiency: '',
      installation: '1) Прави се изкоп за монтиране на съответният каломаслоуловител. Да се спазва естественият откос на почвата при дълбочина до 3.5 м, а при по-голяма дълбочина да се укрепи изкопът. Ако почвата е слаба трябва да се усили по съответните методи. При високи подпочвени води и опасност от изплуване трябва да се вземат необходимите мерки (отводняване на изкопа, дренаж, укрепване на съоръжението срещу изплуване и други ) и да се направят необходимите изчисления. 2) Дъното на изкопът се уплътнява много добре. 3) Изпълнява се пясъчна възглавница ( добре уплътнена ) или бетонова подложка в зависимост от големината на съоръжението и натоварването. 4) Съоръжението се полага в изкопа. 5) Изкопът се запълва като се уплътнява на няколко етапа по 40-50 см. Използва се почва с подходяща зърнометрия или едра фракция пясък. Да се работи внимателно при комбинация от едра фракция пясък и смесена фракция чакъл, за да не се причинят повреди на съоръжението. При монтажът на съоръженията трябва да се спазват изискванията на стандарт БДС EN 1610.',
      techinfo: ['/data/hydrooil-duo/techinfo/1_HYDROOIL-DUO-NS-65ls.pdf', '/data/hydrooil-duo/techinfo/2_HYDROOIL-DUO-NS-75ls.pdf', '/data/hydrooil-duo/techinfo/3_HYDROOIL-DUO-NS-100ls.pdf', '/data/hydrooil-duo/techinfo/4_HYDROOIL-DUO-NS-120ls.pdf', '/data/hydrooil-duo/techinfo/6_HYDROOIL-DUO-NS-175ls.pdf', '/data/hydrooil-duo/techinfo/7_HYDROOIL-DUO-NS 200ls.pdf', '/data/hydrooil-duo/techinfo/8_HYDROOIL-DUO-NS-250ls'],
      drawings: ['/data/hydrooil-duo/drawings/1_HYDROOIL-DUO-NS 65ls.pdf', '/data/hydrooil-duo/drawings/2_HYDROOIL-DUO-NS-75ls.pdf', '/data/hydrooil-duo/drawings/3_HYDROOIL-DUO-NS-100ls.pdf', '/data/hydrooil-duo/drawings/4_HYDROOIL-DUO-NS-120ls.pdf', '/data/hydrooil-duo/drawings/5HYDROOIL-DUO-NS-175ls(okop).pdf', '/data/hydrooil-duo/drawings/6HYDROOIL-DUO-NS-175ls.pdf', '/data/hydrooil-duo/drawings/7HYDROOIL-DUO-NS-200ls.pdf', '/data/hydrooil-duo/drawings/8HYDROOIL-DUO-NS-250ls.pdf', '/data/hydrooil-duo/drawings/9HYDROOIL-DUO-NS-350ls.pdf', '/data/hydrooil-duo/drawings/10HYDROOIL-DUO-NS-400ls.pdf', '/data/hydrooil-duo/drawings/11HYDROOIL-DUO-NS-450ls.pdf', '/data/hydrooil-duo/drawings/12HYDROOIL0-DUO-NS-600ls.pdf'],
      images: ['/data/hydrooil-duo/HYDROOIL-DUO-1.1.png', '/data/hydrooil-duo/HYDROOIL-DUO-1.2.png', '/data/hydrooil-duo/HYDROOIL-DUO-1.3.png', '/data/hydrooil-duo/HYDROOIL-DUO-1.4.png', '/data/hydrooil-duo/HYDROOIL-DUO-1.5.png', '/data/hydrooil-duo/HYDROOIL-DUO-1.6.png', '/data/hydrooil-duo/HYDROOIL-DUO-1.7.png', '/data/hydrooil-duo/HYDROPRO-OIL-SHEMA1.png', '/data/hydrooil-duo/HYDROPRO-OIL-SHEMA3.png', '/data/hydrooil-duo/HYDROPRO-OIL-SHEMA4.png']
    },
    {
      id: 'hydrooil-pro',
      pdf: false,
      title: 'HYDROOIL PRO',
      categories: ['urban-infrastructure-parkinglots', 'transport-infrastructure-highway', 'transport-infrastructure-bridge', 'transport-infrastructure-tunnel', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Паркинги - КМУ'],
      shortdescription: ['Този каломаслоуловител е подходящ за пречистване на средно големи количества течности.'],
      application: 'Течността влиза, през входна тръба, в тялото на каломаслоуловителя. Тежките и твърди частици (кал, пясък и прах) се утаяват на дъното, което играе ролята на утаителна част/утаител (БДС EN 858-1:2002/A1:2004, точка 3.3).  Леките течности (петролни продукти, мазнини и масла) са с по-ниска обемно тегло, за това те изплуват на повърхността, а чистата вода слиза под тях. С времето тези масла се натрупват и се налага съоръженията да се пречистват. За да не преминава непречистена вода през изхода на съоръжението то е снабдено с автоматичен затвор (БДС EN 858-1:2002/A1:2004, точка 3.10 и точка 6.5.3). Той възпрепятства преминаването на течности до почистване на съоръжението. След като съоръжението се пречисти то възобновява пречиствателната си функция.В края на входната тръба има дефлектор.',
      description: 'HYDROOIL PRO се използва за пречистване на дъждовна и отпадъчна вода, замърсена с петролни продукти, мазнини, масла, пясък, прах, кал и други. Този каломаслоуловител е подходящ за пречистване на средно големи количества течности. HYDROOIL PRO се изработва от спирално навити полиетиленови тръби и заварени екструдирани плочи.',
      quality: 'HYDROOIL PRO се изработва спрямо стандарт БДС EN 858. Съоръжението е изпитано притежава необходимите документи гарантиращи неговото качество.',
      efficiency: 'Системата е базирана на физични закони от механиката на флуидите и хидродинамиката. Отличен коалесцентен филтър – финно пречистване чрез геотекстилна обвивка и полиетиленова мрежа. Дефлектор намаляващ динамичните въздействия върху съоръжението и спомагащ ефективното утаяване на твърдите частици (пясък, кал и др.).',
      installation: '1) Прави се изкоп за монтиране на съответният каломаслоуловител. Да се спазва естественият откос на почвата при дълбочина до 3.5 м, а при по-голяма дълбочина да се укрепи изкопът. Ако почвата е слаба трябва да се усили по съответните методи. При високи подпочвени води и опасност от изплуване трябва да се вземат необходимите мерки (отводняване на изкопа, дренаж, укрепване на съоръжението срещу изплуване и други ) и да се направят необходимите изчисления. 2) Дъното на изкопът се уплътнява много добре. 3) Изпълнява се пясъчна възглавница ( добре уплътнена ) или бетонова подложка в зависимост от големината на съоръжението и натоварването. 4) Съоръжението се полага в изкопа. 5) Изкопът се запълва като се уплътнява на няколко етапа по 40-50 см. Използва се почва с подходяща зърнометрия или едра фракция пясък. Да се работи внимателно при комбинация от едра фракция пясък и смесена фракция чакъл, за да не се причинят повреди на съоръжението. При монтажът на съоръженията трябва да се спазват изискванията на стандарт БДС EN 1610.',
      techinfo: ['/data/hydrooil-pro/techinfo/0.1_HYDROOIL-NS-5-ls-SF-2000.pdf', '/data/hydrooil-pro/techinfo/0.2_HYDROOIL-NS-5-ls-SF6000.pdf', '/data/hydrooil-pro/techinfo/0.3_HYDROOIL-NS 21-ls-SF-4000.pdf', '/data/hydrooil-pro/techinfo/1_HYDROOIL-NS-10-ls-SF-1500.pdf', '/data/hydrooil-pro/techinfo/2_HYDROOIL-NS-15-ls-SF-1000.pdf', '/data/hydrooil-pro/techinfo/3_HYDROOIL-NS-20-ls-SF-3000.pdf', '/data/hydrooil-pro/techinfo/4_HYDROOIL-NS-30-ls-SF-3000.pdf', '/data/hydrooil-pro/techinfo/6_HYDROOIL-NS-50-ls-SF-5300.pdf'],
      drawings: ['/data/hydrooil-pro/drawings/0.1_HYDROOIL-NS-5-ls-SF-2000.pdf', '/data/hydrooil-pro/drawings/0.2_HYDROOIL-NS-5-ls-SF6000.pdf', '/data/hydrooil-pro/drawings/0.3_HYDROOIL-NS-21-ls-SF-4000.pdf', '/data/hydrooil-pro/drawings/1_KMU-NS10.pdf', '/data/hydrooil-pro/drawings/2_KMU-NS15.pdf', '/data/hydrooil-pro/drawings/3_KMU-NS20.pdf', '/data/hydrooil-pro/drawings/4_KMU-NS30.pdf', '/data/hydrooil-pro/drawings/5_KMU-NS40.pdf', '/data/hydrooil-pro/drawings/6_KMU-NS50.pdf'],
      images: ['/data/hydrooil-pro/HYDROPRO-OILPRO1.png', '/data/hydrooil-pro/HYDROPRO-OILPRO2.png', '/data/hydrooil-pro/HYDROPRO-OILPRO3.png', '/data/hydrooil-pro/HYDROPRO-OILPRO4.png', '/data/hydrooil-pro/HYDROPRO-OILPRO5.png', '/data/hydrooil-pro/HYDROPRO-OILPRO6.png', '/data/hydrooil-pro/HYDROPRO-OILPRO7.png', '/data/hydrooil-pro/HYDROPRO-OILPRO8.png', '/data/hydrooil-pro/HYDROPRO-OILPRO9.png', '/data/hydrooil-pro/HYDROPRO-OILPRO10.png', '/data/hydrooil-pro/HYDROPRO-OILPRO3.png11', '/data/hydrooil-pro/HYDROPRO-OILPRO12.png', '/data/hydrooil-pro/HYDROPRO-OILPRO13.png']
    },
    {
      id: 'hydrooil-mini',
      pdf: false,
      title: 'HYDROOIL MINI',
      categories: ['urban-infrastructure-parkinglots', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Паркинги - КМУ'],
      shortdescription: ['Този каломаслоуловител е подходящ за пречистване на малки количества течности.'],
      application: 'През входната тръба течностите влизат в HYDROOIL MINI. В края на тръбата е заварен дефлектор, който намалява динамичните въздействия от течността върху съоръжението, с което спомага и по-ефективното утаяване на твърдите частици, които тя носи. Течността съдържа капки мазнини, масла и други петролни продукти, които започват да се задържат по повърхността на коалесцентния филтър. Те постепенно се окрупняват и започват да се издигат на горе, защото са с по-ниско обемно тегло, а филтърът не ги пропуска благодарение на фината си мрежеста структура. По този начин течността, която минава през кутията и изходната тръба е пречистена.',
      description: 'HYDROOIL MINI се използва за пречистване на дъждовна и отпадъчна вода, замърсена с петролни продукти, мазнини, масла, пясък, прах, кал и други. Този каломаслоуловител е подходящ за пречистване на малки количества течности. Подходящ е за подземни паркинги, автосервизи, автомивки и др. HYDROOIL MINI се изработва от спирално навити полиетиленови тръби и екструдирани плочи, заварени с ръчен екструдер.',
      quality: 'HYDROOIL MINI се изработва спрямо стандарт БДС EN 858. Спрямо реакцията на огън HYDROOIL MINI e клас Е. Съоръжението е изпитано и притежава необходимите документи гарантиращи неговото качество.',
      efficiency: 'Системата е базирана на физични закони от механиката на флуидите и хидродинамиката. Отличен коалесцентен филтър – финно пречистване чрез геотекстилна обвивка и полиетиленова мрежа. Дефлектор намаляващ динамичните въздействия върху съоръжението и спомагащ ефективното утаяване на твърдите частици (пясък, кал и др.).',
      installation: '1) Прави се изкоп за монтиране на съответният каломаслоуловител. Да се спазва естественият откос на почвата при дълбочина до 3.5 м, а при по-голяма дълбочина да се укрепи изкопът. Ако почвата е слаба трябва да се усили по съответните методи. При високи подпочвени води и опасност от изплуване трябва да се вземат необходимите мерки (отводняване на изкопа, дренаж, укрепване на съоръжението срещу изплуване и други ) и да се направят необходимите изчисления. 2) Дъното на изкопът се уплътнява много добре. 3) Изпълнява се пясъчна възглавница ( добре уплътнена ) или бетонова подложка в зависимост от големината на съоръжението и натоварването. 4) Съоръжението се полага в изкопа. 5) Изкопът се запълва като се уплътнява на няколко етапа по 40-50 см. Използва се почва с подходяща зърнометрия или едра фракция пясък. Да се работи внимателно при комбинация от едра фракция пясък и смесена фракция чакъл, за да не се причинят повреди на съоръжението. При монтажът на съоръженията трябва да се спазват изискванията на стандарт БДС EN 1610.',
      techinfo: ['/data/hydrooil-mini/techinfo/1_HYDROOIL-MINI-NS-1.5ls.pdf', '/data/hydrooil-mini/techinfo/2_HYDROOIL-MINI-NS-3ls.pdf'],
      drawings: ['/data/hydrooil-mini/drawings/1_KMU-KYDROOIL-MINI-NS-1.5ls.pdf', '/data/hydrooil-mini/drawings/2_KMU-KYDROOIL-MINI-NS-3ls.pdf'],
      images: ['/data/hydrooil-mini/HYDROPRO-MINI-7.png', '/data/hydrooil-mini/HYDROPRO-MINI-8.png', '/data/hydrooil-mini/HYDROPRO-MINI-9.png', '/data/hydrooil-mini/HYDROPRO-MINI-10.png', '/data/hydrooil-mini/HYDROPRO-MINI-11.png', '/data/hydrooil-mini/HYDROPRO-MINI-12.png', '/data/hydrooil-mini/HYDROPRO-MINI-13.png', '/data/hydrooil-mini/HYDROPRO-MINI-14.png', '/data/hydrooil-mini/HYDROPRO-MINI-15.png', '/data/hydrooil-mini/HYDROPRO-MINI-16.png', '/data/hydrooil-mini/HYDROPRO-MINI-17.png', '/data/hydrooil-mini/HYDROPRO-MINI-18.png', '/data/hydrooil-mini/HYDROPRO-MINI-19.png', '/data/hydrooil-mini/HYDROPRO-MINI-20.png']
    },
    {
      id: 'shaft-drainage',
      pdf: false,
      title: 'HYDROOIL DRAINAGE',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Паркинги - КМУ'],
      shortdescription: ['Препоръчват се за дрениране на сгради, магистрали, улици, летища, паркинги и др.'],
      application: '-	HYDROPRO предлага шахти, които намират широко приложение в изграждането на: дъждоприемни системи, ревизионни шахти, пречиствателни станции, помпени станции, шахти за сметища, водомерни сградни отклонения и др.',
      description: '-	Дренажните шахти се използват за се препоръчват за дрениране на сгради, магистрали, улици, летища, паркинги, при изграждане на депа за отпадъци и др. Предлагат се като монолитна конструкция с капак тип решетка. Възможно е изпълнението на двуставни дренажници, като за тази цел се използват две дренажни шахти с различна височина, свързани помежду им.',
      quality: '-	ПЕ и ПП шахти са екологична и евтина алтернатива в експлоатационно отношение спрямо тежките и сложни за поддържане бетонни конструкции. Полиетилена и полипропилена имат добри свойства по отношение на твърдост и еластичност на материала. Качествата им ги правят подходящ материал за нашите съоръжения, които изпълняват комплексните изисквания. ',
      efficiency: '-	Диаметрите, които може да поръчате са от DN/ID 160 до DN/ID 2400. Шахтите от спирално навити тръби са с диаметър от DN/ID 500 до DN/ID 2400.Монтаж',
      techinfo: [],
      drawings: [],
      images: ['/data/shaft-drainage/drainageshfatmini1.png', '/data/shaft-drainage/drainageshfatmini2.png', '/data/shaft-drainage/drainageshfatmini3', '/data/shaft-drainage/drainageshfatmini4']
    },
    {
      id: 'shaft-cascading',
      pdf: false,
      title: 'HYDROOIL Cascading',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Паркинги - Шахти'],
      shortdescription: ['При полагане на канали височинната канализация налага канализационният клон да се включи над дъното на шахтата.'],
      application: '-	HYDROPRO предлага шахти, които намират широко приложение в изграждането на: дъждоприемни системи, ревизионни шахти, пречиствателни станции, помпени станции, шахти за сметища, водомерни сградни отклонения и др.',
      description: '-	Когато при полагане на канали височинната канализация налага канализационният клон да се включи над дъното на шахтата, при височина на пада от 0,5 м. до 6 м., се използват КАСКАДНИ ШАХТИ. Проектират се в зависимост от вида на входа (муфиран или немуфиран). Свързващите тръби могат да бъдат гладкостенни или профилирани. Каскадната система се състои от ревизионна шахта с довеждаща тръба.',
      quality: '-	ПЕ и ПП шахти са екологична и евтина алтернатива в експлоатационно отношение спрямо тежките и сложни за поддържане бетонни конструкции. Полиетилена и полипропилена имат добри свойства по отношение на твърдост и еластичност на материала. Качествата им ги правят подходящ материал за нашите съоръжения, които изпълняват комплексните изисквания. ',
      efficiency: '-	Диаметрите, които може да поръчате са от DN/ID 160 до DN/ID 2400. Шахтите от спирално навити тръби са с диаметър от DN/ID 500 до DN/ID 2400.',
      techinfo: [],
      drawings: [],
      images: ['/data/shaft-cascading/cascadeshaft1.png', '/data/shaft-cascading/cascadeshaft2.png', '/data/shaft-cascading/cascadeshaft3.png', '/data/shaft-cascading/cascadeshaft4.png']
    },
    {
      id: 'shaft-eccentric',
      pdf: false,
      title: 'HYDROOIL Еccentric',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Паркинги - Шахти'],
      shortdescription: ['Eкологична и евтина алтернатива в експлоатационно отношение спрямо тежките и сложни за поддържане бетонни конструкции.'],
      application: '-	HYDROPRO предлага шахти, които намират широко приложение в изграждането на: дъждоприемни системи, ревизионни шахти, пречиствателни станции, помпени станции, шахти за сметища, водомерни сградни отклонения и др.',
      description: '-	Този тип шахта е тангенциално позиционирана спрямо вертикалната тръба, което означава, че се измества от средата. Тангенциалната част на шахтата се прави с диаметър от ID 1300 до ID 2000 мм, а вертикалната част – до ID 1000 мм. От вътрешната страна има възможност за поставяне на стълба.',
      quality: '-	ПЕ и ПП шахти са екологична и евтина алтернатива в експлоатационно отношение спрямо тежките и сложни за поддържане бетонни конструкции. Полиетилена и полипропилена имат добри свойства по отношение на твърдост и еластичност на материала. Качествата им ги правят подходящ материал за нашите съоръжения, които изпълняват комплексните изисквания. ',
      efficiency: '-	Диаметрите, които може да поръчате са от DN/ID 160 до DN/ID 2400. Шахтите от спирално навити тръби са с диаметър от DN/ID 500 до DN/ID 2400.Монтаж',
      techinfo: [],
      drawings: [],
      images: ['/data/shaft-eccentric/eccentricshaft.png', '/data/shaft-eccentric/eccentricshaft2.png', '/data/shaft-eccentric/eccentricshaft3.png', '/data/shaft-eccentric/eccentricshaft4.png', '/data/shaft-eccentric/eccentricshaft5.png', '/data/shaft-eccentric/eccentricshaft6.png', '/data/shaft-eccentric/eccentricshaft7.png', '/data/shaft-eccentric/eccentricshaft8.png', '/data/shaft-eccentric/eccentricshaft9.png', '/data/shaft-eccentric/eccentricshaft10.png']
    },
    {
      id: 'outflowpoints-revision',
      pdf: false,
      title: 'HYDROOIL Revision',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-tunnel', 'shopping-areas-gas-stations-and-car-service', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Паркинги - Точков отток'],
      shortdescription: ['Използват се за смесена канализация при построяване на автомагистрали.'],
      application: '-	HYDROPRO предлага шахти, които намират широко приложение в изграждането на: дъждоприемни системи, ревизионни шахти, пречиствателни станции, помпени станции, шахти за сметища, водомерни сградни отклонения и др.',
      description: '-	Ревизионните и дъждоприемни шахти, които HYDROPRO предлага често се използват за смесена канализация при построяване на автомагистрали.',
      quality: '-	ПЕ и ПП шахти са екологична и евтина алтернатива в експлоатационно отношение спрямо тежките и сложни за поддържане бетонни конструкции. Полиетилена и полипропилена имат добри свойства по отношение на твърдост и еластичност на материала. Качествата им ги правят подходящ материал за нашите съоръжения, които изпълняват комплексните изисквания. ',
      efficiency: '-	Диаметрите, които може да поръчате са от DN/ID 160 до DN/ID 2400. Шахтите от спирално навити тръби са с диаметър от DN/ID 500 до DN/ID 2400.',
      techinfo: [],
      drawings: [],
      images: ['/data/outflowpoints-revision/hydroprorevision1.png', '/data/outflowpoints-revision/hydroprorevision2.png', '/data/outflowpoints-revision/hydroprorevision3.png', '/data/outflowpoints-revision/hydroprorevision4.png', '/data/outflowpoints-revision/hydroprorevision5.png']
    },
    {
      id: 'outflowpoints-rainwater',
      pdf: false,
      title: 'HYDROOIL Rainwater',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-tunnel', 'shopping-areas-gas-stations-and-car-service', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Паркинги - Точков отток'],
      shortdescription: ['Използват се за отвеждане на дъждовни води от пътната мрежа, обществени и промишлени площи.'],
      application: '-	HYDROPRO предлага шахти, които намират широко приложение в изграждането на: дъждоприемни системи, ревизионни шахти, пречиствателни станции, помпени станции, шахти за сметища, водомерни сградни отклонения и др.',
      description: '-	Дъждоприемните шахти се използват за отвеждане на дъждовни води от пътната мрежа, обществени и промишлени площи. Предлагат се като монолитна конструкция с капак тип решетка. Възможно е изпълнението на двуставни дъждоприемници, като за тази цел се използват две дъждоприемни шахти с различна височина, свързани помежду им.',
      quality: '-	ПЕ и ПП шахти са екологична и евтина алтернатива в експлоатационно отношение спрямо тежките и сложни за поддържане бетонни конструкции. Полиетилена и полипропилена имат добри свойства по отношение на твърдост и еластичност на материала. Качествата им ги правят подходящ материал за нашите съоръжения, които изпълняват комплексните изисквания. ',
      efficiency: '-	Диаметрите, които може да поръчате са от DN/ID 160 до DN/ID 2400. Шахтите от спирално навити тръби са с диаметър от DN/ID 500 до DN/ID 2400 Монтаж.',
      techinfo: [],
      drawings: [],
      images: ['/data/outflowpoints-rainwater/rainwaterschug500.png', '/data/outflowpoints-rainwater/rainwaterschug504.png', '/data/outflowpoints-rainwater/rainwaterschug5002.png', '/data/outflowpoints-rainwater/rainwaterschut500.png']
    },
    {
      id: 'hydrooil-lineardrainer',
      pdf: false,
      title: 'HYDROOIL Lineardrainer',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Паркинги - Линеен отводнител'],
      shortdescription: 'Съчетава линеен отводнител и колектор в едно цялострно съоржение.',
      application: 'HYDROPRO LINE съчетава линеен отводнител и колектор в едно цялострно съоржение. Това увеличава скоростта на изпълнение на строителството. HYDROPRO LINE намира приложение при отводняване на индустриални площи, логистични паркове, пристанища и летища.',
      description: 'HYDROPRO LINE е подходящ за събиране и използване на дъждовни води, като едновременно съчетава отводнител и колектор в едно съоръжение. Отличава се с висока водоплътност чрез електрозаваряване. Стандартната производствена дължина е 6 метра, предлагаме и нестандартни размери при запитване. Тези нестандартни размери води до намаляване на броят на връзките и съответно муфите.',
      quality: 'HYDROPRO LINE се изработва спрямо стандарт БДС EN 1433:2003+AC:2005+A1:2007. Съоръжението е изпитано и притежава необходимите документи гарантиращи неговото качество.',
      installation: '1) Прави се изкоп с необходимата дълбочина. Необходимо е да се спазва естествения откос на почвата при дълбочина до 3,5 м., а при по-голяма дълбочина да се укрепи изкопа. Ако почвата е слаба трябва да се усили по съответните методи.\n2) Изпълнява се пясъчна възглавница(добре уплътнена) или бетонова подложка в зависимост от големината на линейния отводнител и/или натоварването.\n3) Отводнителят се поставя внимателно в изкопа. Полага се бетон с дебелина на пласта приблизително 1/3 от височината на монтажните крачета. Това се прави с цел фиксиране на монтираното тяло.\n4) Излива се още един пласт бетон, в който да бъдат закотвени последващи армировъчни пръти ( при нужда).\n5) При по-голямо натоварване се поставя и стоманобетонен кожух. Полага се нужното количество бетон, като се осигуряват надлъжни изолационни фуги.',
      efficiency: 'Ефективността се повишава от ниското тегло, спрямо бетонните и металните съоръжения. Също така е на лице бърз и лесен монтаж, придружен с високата устойчивост. Метални задържащи фиксатори осигуряват проектното положение по време на бетонирането. Всичко това спестява време и пари.',
      techinfo: [ '/data/lineardrainer/techinfo/206-references.pdf', '/data/lineardrainer/techinfo/HYDROPRO-LINE-BG-KATALOG-5p.pdf', '/data/lineardrainer/techinfo/HYDROPRO-LINE-iD250-Produktinformation.pdf', '/data/lineardrainer/techinfo/HYDROPRO-LINE-iD300-Produktinformation.pdf', , '/data/lineardrainer/techinfo/HYDROPRO-LINE-iD500-Produktinformation.pdf', , '/data/lineardrainer/techinfo/HYDROPRO-LINE-iD900-Produktinformation.pdf'],
      drawings: ['/data/lineardrainer/drawings/HYDROPRO_Line_iD_500.dwg','/data/lineardrainer/drawings/HYDROPRO_Line_iD_500.pdf','/data/lineardrainer/drawings/HYDROPRO_Line_iD_360.pdf','/data/lineardrainer/drawings/HYDROPRO_Line_iD_360.dwg','/data/lineardrainer/drawings/HYDROPRO_Line_iD_250.pdf', '/data/lineardrainer/drawings/HYDROPRO_Line_iD_250.dwg', '/data/lineardrainer/drawings/HYDROPRO-LINE-CONECTION-iD500.pdf', '/data/lineardrainer/drawings/HYDROPRO-LINE-CONNECTION-iD200.pdf', '/data/lineardrainer/drawings/HYDROPRO-LINE-CONNECTION-iD250.pdf', '/data/lineardrainer/drawings/HYDROPRO-LINE-CONNECTION-iD300.pdf', '/data/lineardrainer/drawings/HYDROPRO-LINE-CONNECTION-TWO-HDROLINES-iD200.pdf', '/data/lineardrainer/drawings/HYDROPRO-LINE-CONNECTION-TWO-HDROLINES-iD300.pdf', '/data/lineardrainer/drawings/HYDROPRO-LINE-CONNECTION-TWO-HDROLINES-iD500.pdf', '/data/lineardrainer/drawings/HYDROPRO-Line-iD-200.pdf', '/data/lineardrainer/drawings/HYDROPRO-Line-iD-200-6m.pdf', '/data/lineardrainer/drawings/HYDROPRO-Line-iD-250.pdf', '/data/lineardrainer/drawings/HYDROPRO-Line-iD-250-6m.pdf', '/data/lineardrainer/drawings/HYDROPRO-Line-iD-300.pdf', '/data/lineardrainer/drawings/HYDROPRO-Line-iD-300-6m.pdf', '/data/lineardrainer/drawings/HYDROPRO-Line-iD-500.pdf', '/data/lineardrainer/drawings/HYDROPRO-Line-iD-500-6m.pdf', '/data/lineardrainer/drawings/Детайл-на-монтаж.pdf', '/data/lineardrainer/drawings/kojuh-lineen-otvodnitel-f500.pdf'],
      images: ['/data/lineardrainer/HYDROPROLINE-1.png', '/data/lineardrainer/HYDROPRO-LINE-2.png', '/data/lineardrainer/HYDROPRO-LINE-3.png', '/data/lineardrainer/HYDROPRO-LINE-4.png', '/data/lineardrainer/HYDROPRO-LINE-5.png']
    },
    {
      id: 'tanks',
      pdf: false,
      title: 'Резервоари',
      categories: ['transport-infrastructure-tunnel', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Паркинги - Резервоари'],
      shortdescription: ['Подходящи за питейни и отпадъчни води, за съхранение на вода при противопожарни нужди'],
      application: 'Резервоарите ни са подходящи за питейни и отпадъчни води. Също така са подходящи за съхранение на вода при противопожарни нужди и събиране и използване на дъждовни води.',
      description: 'HYDROPRO предлага хоризонтални и вертикално стоящи резервоари. Те се изработват от полиетилен или полипропилен. Технологията на производство позволява създаването на резервоари с голям размер и капацитет. Приемаме и индивидуални поръчки. Прилагаме високи стандарти за качество. Минималната степен на деформация и високата якост на материала, осигуряват на резервоарите стабилност и дълъг период на експлоатация. Резервоарите са подходящи за подземен монтаж.',
      quality: '-	Продуктите ни се произвеждат от PE или PEHD. Придружени са със всички нужни документи за качество. Изпълняваме и индивидуални поръчки.',
      efficiency: 'Имаме взможност за производство на вертикални резервоари. Те се изработват от полиетилен или полипропилен. Технологията на производство позволява създаването на резервоари с голям размер и капацитет. Приемаме и индивидуални поръчки. Прилагаме високи стандарти за качество. Минималната степен на деформация и високата якост на материала, осигуряват на резервоарите стабилност и дълъг период на експлоатация. Резервоарите са подходящи за подземен монтаж.',
      installation: 'Прави се изкоп за монтиране на съответният каломаслоуловител. Да се спазва естественият откос на почвата при дълбочина до 3.5 м, а при по-голяма дълбочина да се укрепи изкопът. Ако почвата е слаба трябва да се усили по съответните методи. При високи подпочвени води и опасност от изплуване трябва да се вземат необходимите мерки (отводняване на изкопа, дренаж, укрепване на съоръжението. Дъното на изкопът се уплътнява много добре. Изпълнява се пясъчна възглавница ( добре уплътнена ) или бетонова подложка в зависимост от големината на съоръжението и натоварването. Съоръжението се полага в изкопа. Изкопът се запълва като се уплътнява на няколко етапа по 40-50 см. Използва се почва с подходяща зърнометрия или едра фракция пясък. Да се работи внимателно при комбинация от едра фракция пясък и смесена фракция чакъл, за да не се причинят повреди на съоръжението. При монтажът на съоръженията трябва да се спазват изискванията на стандарт БДС EN 1610.',
      techinfo: ['/data/tanks/techinfo/techinfo1.pdf', '/data/tanks/techinfo/techinfo2.pdf', '/data/tanks/techinfo/techinfo3.pdf', '/data/tanks/techinfo/techinfo4.pdf'],
      drawings: ['/data/tanks/drawings/Hydrotank-1.pdf', '/data/tanks/drawings/Hydrotank-2.pdf', '/data/tanks/drawings/Hydrotank-3.pdf', '/data/tanks/drawings/Hydrotank-4.pdf'],
      images: [ '/data/tanks/HYDROtank-vertical7.png', '/data/tanks/HYDROtank-vertical8.png', '/data/tanks/HYDROtank-vertical9.png', '/data/tanks/HYDROtank-vertical10.png', '/data/tanks/HYDROtank-vertical11.png', '/data/tanks/HYDROtank-vertical12.png', '/data/tanks/HYDROtank-vertical13.png', '/data/tanks/HYDROtank-vertical14.png', '/data/tanks/HYDROtank-vertical1.png', '/data/tanks/HYDROtank-vertical2.png', '/data/tanks/HYDROtank-vertical3.png', '/data/tanks/HYDROtank-vertical4.png', '/data/tanks/HYDROtank-vertical5.png', '/data/tanks/HYDROtank-vertical6.png',]
    },
    {
      id: 'hydrotek-castiron-accessory',
      pdf: true,
      title: 'Универсален ключ за отваряне',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Чугунени изделия за пътна инфраструктура'],
      shortdescription: 'За болтове с квардратни глави M16 и M20 (DIN 478), кука за издърпване, права щанга',
      images: ['/data/hydrotek-castiron-accessory/universalen-kliuch-za-otvarqne.png'],
      pdfURL: '/data/hydrotek-castiron-accessory/universalen-kliuch.pdf'
    },
    {
      id: 'hydrotek-castiron-lids-slaney',
      pdf: true,
      title: 'Капак "SLANEY" 600x600',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Капаци за вграждане'],
      shortdescription: 'Иновативна автоматична система за заключване с пружина',
      images: ['/data/hydrotek-castiron-lids-slaney/SLANEY-600x600.png'],
      pdfURL: '/data/hydrotek-castiron-lids-slaney/600x600_SLANEY_B125_892487_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-lids-douglas',
      pdf: true,
      title: 'Капак "DOUGLAS" 600 x 600 - водонепропусклив',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Капаци за вграждане'],
      shortdescription: 'Масивен квадратен капак заключен с 4 болта от неръждаема стомана',
      images: ['/data/hydrotek-castiron-lids-douglas/DOUGLAS-600x600.png'],
      pdfURL: '/data/hydrotek-castiron-lids-douglas/600x600_DOUGLAS_D400_894539_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-revision-hydrotight-square',
      pdf: true,
      title: 'Капак "1000 / 600" - водонепропусклив',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Ревизионни капаци - водопкътни'],
      shortdescription: 'Капак 1000 заключен с 6 болта от неръждаема стомана, Капак 600 заключен с 4 болта от неръждаема стомана',
      images: ['/data/hydrotek-castiron-revision-hydrotight-square/HYDROtight-square.png'],
      pdfURL: '/data/hydrotek-castiron-revision-hydrotight-square/1000_tight_square_D400_8945701_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-revision-hydrotight-1000-600',
      pdf: true,
      title: 'Капак "1000 / 600" - водонепропусклив',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Ревизионни капаци - водопкътни'],
      shortdescription: 'Уплътнение в капака и рамката, квадратна рамка с размери 1237х1237 mm',
      images: ['/data/hydrotek-castiron-revision-hydrotight-1000-600/HYDROtight 1000-600.png'],
      pdfURL: '/data/hydrotek-castiron-revision-hydrotight-1000-600/1000_tight_D400_894570_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-revision-hydrotight-600',
      pdf: true,
      title: 'Капак "HYDROtight" 600',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Ревизионни капаци - водопкътни'],
      shortdescription: 'Заключва се с помощта на 3 бр. винтове (неръждаема стомана)',
      images: ['/data/hydrotek-castiron-revision-hydrotight-600/HYDROtight-600.png'],
      pdfURL: '/data/hydrotek-castiron-revision-hydrotight-600/600_HYDROtight_D400_894675_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-revision-hydroproof-600',
      pdf: true,
      title: 'Капак "HYDROproof" 600',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Ревизионни капаци - водопкътни'],
      shortdescription: 'Заключва се с помощта на 4бр. винтове от неръждаема стомана',
      images: ['/data/hydrotek-castiron-revision-hydroproof-600/HYDROproof-600.png'],
      pdfURL: '/data/hydrotek-castiron-revision-hydroproof-600/610_HYDROproof_D400_895753_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-revision-econtight-800',
      pdf: true,
      title: 'Капак 800 - водонепропусклив',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Ревизионни капаци - водопкътни'],
      shortdescription: 'Дълъг живот, висока абразивна устойчивост на екстремни натоварвания',
      images: ['/data/hydrotek-castiron-revision-econtight-800/ECON-tight-800.png'],
      pdfURL: '/data/hydrotek-castiron-revision-econtight-800/800_tight_D400_84834_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-grilldrains-grill-300x400',
      pdf: true,
      title: 'Решетка "GULLY TOP" 300x300',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Решетка за улични оттоци'],
      shortdescription: 'Рамка - бетон / чугун, Решетка - чугун',
      images: ['/data/hydrotek-castiron-grilldrains-grill-300x400/RESHETKA-ZA-UO-2.png'],
      pdfURL: '/data/hydrotek-castiron-grilldrains-grill-300x400/300x300_B125_902291_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-grilldrains-grill-300x300',
      pdf: true,
      title: 'Точков отток "GULLY BOX" 300x300',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Решетка за улични оттоци'],
      shortdescription: 'Хоризонтално оттичане за връзка с тръба DN 100 / DN 150',
      images: ['/data/hydrotek-castiron-grilldrains-grill-300x300/RESHETKA-ZA-UO.png'],
      pdfURL: '/data/hydrotek-castiron-grilldrains-grill-300x300/300x300_B125-D400_90229090_90429090_90229080_90429080_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-grilldrains-revision',
      pdf: true,
      title: 'Ревизия за водосточни тръби - DN 100 - Висока версия',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Решетка за улични оттоци'],
      shortdescription: 'Висока версия с кошничка за отпадъци',
      images: ['/data/hydrotek-castiron-grilldrains-revision/revizii-za-vodostochni-trubi.png'],
      pdfURL: '/data/hydrotek-castiron-grilldrains-revision/100_Roof_Drainage_long_990100_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-grilldrains-revision-1',
      pdf: true,
      title: 'Ревизия за водосточни тръби - DN 100 - Къса версия',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Решетка за улични оттоци'],
      shortdescription: 'Къса версия с подвижен чугунен центрубежен пръстен',
      images: ['/data/hydrotek-castiron-grilldrains-revision-1/revizii-za-vodostochni-trub-1.png'],
      pdfURL: '/data/hydrotek-castiron-grilldrains-revision-1/100_Roof_Drainage_short_9901001_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-grilldrains-econ500x500',
      pdf: true,
      title: 'Решетка клас: C250 "ECON" 500x500 - права',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Решетка за улични оттоци'],
      shortdescription: 'Клас: C250, Тегло: ca. 55 kg',
      images: ['/data/hydrotek-castiron-grilldrains-econ500x500/ECON-500x500.png'],
      pdfURL: '/data/hydrotek-castiron-grilldrains-econ500x500/500x500_ECON_flat_C250_933951_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-grilldrains-econ500x500-1',
      pdf: true,
      title: 'Решетка клас: D400 "ECON" 500x500 - права',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Решетка за улични оттоци'],
      shortdescription: 'Клас: D400, Тегло: ca. 57 kg',
      images: ['/data/hydrotek-castiron-grilldrains-econ500x500-1/ECON 500x500.1.png'],
      pdfURL: '/data/hydrotek-castiron-grilldrains-econ500x500-1/500x500_ECON_flat_D400_934951_BG.png'
    },
    {
      id: 'hydrotek-castiron-grilldrains-econ300x500',
      pdf: true,
      title: 'Решетка клас: C250 "ECON" 300x500 - права',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Решетка за улични оттоци'],
      shortdescription: 'Клас: C250, Тегло: ca. 37 kg',
      images: ['/data/hydrotek-castiron-grilldrains-econ300x500/ECON-300x500.png'],
      pdfURL: '/data/hydrotek-castiron-grilldrains-econ300x500/300x500_ECON_flat_C250_933921_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-grilldrains-econ300x500-1',
      pdf: true,
      title: 'Решетка клас: D400 "ECON" 300x500 - права',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Решетка за улични оттоци'],
      shortdescription: 'Клас: D400, Тегло: ca. 39 kg',
      images: ['/data/hydrotek-castiron-grilldrains-econ300x500-1/ECON-300x500.1.png'],
      pdfURL: '/data/hydrotek-castiron-grilldrains-econ300x500-1/300x500_ECON_flat_D400_934941_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-selfleveling-scandic800',
      pdf: true,
      title: 'Капак "SCANDIC" SN 800',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Самонивелиращи капаци'],
      shortdescription: 'Чрез позиционирането на заключващите точки капака е центриран и фиксиран в рамката',
      images: ['/data/hydrotek-castiron-selfleveling-scandic800/SCANDIC-SN-800.png'],
      pdfURL: '/data/hydrotek-castiron-selfleveling-scandic800/800_SCANDIC_SN_D400_894548_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-selfleveling-euro-600',
      pdf: true,
      title: 'Капак "EURO" SN 600, вентилиран',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Самонивелиращи капаци'],
      shortdescription: 'Иновативна автоматична 3 точкова пружинна заключваща система',
      images: ['/data/hydrotek-castiron-selfleveling-euro-600/EURO-SN-600.png'],
      pdfURL: '/data/hydrotek-castiron-selfleveling-euro-600/600_EURO_SN_mV_D400_8946641_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-selfleveling-euro-600-1',
      pdf: true,
      title: 'Капак "EURO" SN 600',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Самонивелиращи капаци'],
      shortdescription: 'Не позволява неоторизирано отваряне',
      images: ['/data/hydrotek-castiron-selfleveling-euro-600-1/EURO-SN-600.1.png'],
      pdfURL: '/data/hydrotek-castiron-selfleveling-euro-600-1/600_EURO_SN_D400_894664_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-selfleveling-econ-800',
      pdf: true,
      title: 'Самонивелиращ капак "ECON" SN 800 вентилиран',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Самонивелиращи капаци'],
      shortdescription: 'Регулира се самостоятелно докато достигне нивото на пътя',
      images: ['/data/hydrotek-castiron-selfleveling-econ-800/ECON-SN-800.png'],
      pdfURL: '/data/hydrotek-castiron-selfleveling-econ-800/800_ECON_SN_800_mV_D400_894512_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-selfleveling-econ-800-1',
      pdf: true,
      title: 'Самонивелиращ капак "ECON" SN 800',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Самонивелиращи капаци'],
      shortdescription: 'Регулиране на височината - с вложка за възглавница',
      images: ['/data/hydrotek-castiron-selfleveling-econ-800-1/ECON-SN-800.1.png'],
      pdfURL: '/data/hydrotek-castiron-selfleveling-econ-800-1/100005910_D_und_A.pdf'
    },
    {
      id: 'hydrotek-castiron-selfleveling-econsn-600-190',
      pdf: true,
      title: 'Самонивелиращ капак "ECON" SN 600 / 190 вентилиран',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Самонивелиращи капаци'],
      shortdescription: 'Опция за капак с вентилационни отвори',
      images: ['/data/hydrotek-castiron-selfleveling-econsn-600-190/ECON-SN-600-190.png'],
      pdfURL: '/data/hydrotek-castiron-selfleveling-econsn-600-190/600_ECON_SN_mV_D400_814122_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-selfleveling-econsn-600-190-1',
      pdf: true,
      title: 'Самонивелиращ капак "ECON" SN 600 / 190',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Самонивелиращи капаци'],
      shortdescription: 'Чрез позиционирането на заключващите точки капака е центриран и фиксиран в рамката',
      images: ['/data/hydrotek-castiron-selfleveling-econsn-600-190-1/ECON-SN-600-190-1.png'],
      pdfURL: '/data/hydrotek-castiron-selfleveling-econsn-600-190-1/600_ECON_SN_D400_814123_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-flange-ricon600p',
      pdf: true,
      title: 'Капак "RICON" 600',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Фланшови рамки'],
      shortdescription: 'Външен размер на рамката 850mm',
      images: ['/data/hydrotek-castiron-flange-ricon600p/RICON-600.2.png'],
      pdfURL: '/data/hydrotek-castiron-flange-ricon600p/600_RICON_D400_785_D400_894686_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-flange-ricon600',
      pdf: true,
      title: 'Капак "RICON" 600 вентилиран',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Фланшови рамки'],
      shortdescription: 'Позволява монтаж на кошница за едри отпадъци тип "Австрия"',
      images: ['/data/hydrotek-castiron-flange-ricon600/RICON-600.png'],
      pdfURL: '/data/hydrotek-castiron-flange-ricon600/600_RICON_D400_785_mV_D400_8946861_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-flange-euro600',
      pdf: true,
      title: 'Капак "EURO" 600',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Фланшови рамки'],
      shortdescription: 'Опция за клас на натоварване A15 и C250 и вентилиран капак',
      images: ['/data/hydrotek-castiron-flange-euro600/ECON-600.png'],
      pdfURL: '/data/hydrotek-castiron-flange-euro600/ECON-600.pdf'
    },
    {
      id: 'hydrotek-castiron-flange-euro600-1',
      pdf: true,
      title: 'Капак "EURO" 600',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Фланшови рамки'],
      shortdescription: 'Капак с отвор и тапа за допълнително обратно заключване',
      images: ['/data/hydrotek-castiron-flange-euro600-1/EURO-600.1.png'],
      pdfURL: '/data/hydrotek-castiron-flange-euro600-1/600_EURO_C250_893593_BG.png'
    },
    {
      id: 'hydrotek-castiron-flange-econ800',
      pdf: true,
      title: 'Капак "ECON" 800 вентилиран',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Фланшови рамки'],
      shortdescription: 'Опция за газов амортисьор',
      images: ['/data/hydrotek-castiron-flange-econ800/ECON-800.png'],
      pdfURL: '/data/hydrotek-castiron-flange-econ800/800_ECON_D400_mV_894510_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-flange-econ800-1',
      pdf: true,
      title: 'Капак "ECON" 800',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Фланшови рамки'],
      shortdescription: 'Опция за самоневилираща се рамка',
      images: ['/data/hydrotek-castiron-flange-econ800-1/ECON-800.1.png'],
      pdfURL: '/data/hydrotek-castiron-flange-econ800-1/800_ECON_D400_894520_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-flange-econ600',
      pdf: true,
      title: 'Капак "ECON" 600 вентилиран',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Фланшови рамки'],
      shortdescription: 'Criss-cross дизайн за максимална сигурност',
      images: ['/data/hydrotek-castiron-flange-econ600/EURO-600.png'],
      pdfURL: '/data/hydrotek-castiron-flange-econ600/600_ECON_mV_D400_894718_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-flange-econ600-1',
      pdf: true,
      title: 'Капак "ECON" 600',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Фланшови рамки'],
      shortdescription: 'Клас на натоварване E600',
      images: ['/data/hydrotek-castiron-flange-econ600-1/600_ECON.png'],
      pdfURL: '/data/hydrotek-castiron-flange-econ600-1/600_ECON_D400_894708_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-flange-econ600-2',
      pdf: true,
      title: 'Капак "ECON" 600 87kg',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Фланшови рамки'],
      shortdescription: 'Опция за капак с вентилационни отвори',
      images: ['/data/hydrotek-castiron-flange-econ600-2/ECON-600.2.png'],
      pdfURL: '/data/hydrotek-castiron-flange-econ600-2/600_ECON_E600_895708_BG.pdf'
    },
    {
      id: 'hydrotek-castiron-flange-econ600-3',
      pdf: true,
      title: 'ECON 600',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Фланшови рамки'],
      shortdescription: '4 джоба за събиране на отпадъци',
      images: ['/data/hydrotek-castiron-flange-econ600-3/ECON-600.3.png'],
      pdfURL: '/data/hydrotek-castiron-flange-econ600-3/600_ECON_mV_D400_895718_BG.pdf'
    },
    {
      id: 'hydrotek-security',
      pdf: true,
      title: 'Охранителни гарнитури',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Охранителни гарнитури'],
      shortdescription: 'Безшумен при преминаване, минимално износване на материала',
      images: ['/data/hydrotek-ohranitelni-garnituri/ohranitelni-garnituri.png'],
      pdfURL: '/data/hydrotek-ohranitelni-garnituri/katalog-surface.pdf'
    },
    {
      id: 'hydrotek-centralparts-bars',
      pdf: true,
      title: 'Решетки за защита корените и стъблата на дърветата',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets'],
      categoriesNames: ['Хидротек - Обзавеждане за централни части'],
      shortdescription: 'Предпазители от дърво, изработени от горещо поцинкована стомана',
      images: ['/data/hydrotek-centralparts-bars/reshetka.png'],
      pdfURL: '/data/hydrotek-centralparts-bars/HYDROTEC-CATALOG-TREE.pdf'
    },
    {
      id: 'hydrotek-centralparts-container',
      pdf: true,
      title: 'Контейнер за отпадъци и пепелници',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets'],
      categoriesNames: ['Хидротек - Обзавеждане за централни части'],
      shortdescription: 'Отпадъци и пепелници',
      images: ['/data/hydrotek-centralparts-container/KONTAINER-ZA-OTPADUCI.png'],
      pdfURL: '/data/hydrotek-centralparts-container/HYDROTEC-FAIL-1.pdf'
    },
    {
      id: 'hydrotek-lineardrainer-bars',
      pdf: true,
      title: 'Система решетки слот ',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Линейно отводняване'],
      shortdescription: 'Защитен ръб от поцинкована стомана, улей от фибробетон C35/45 с фибри',
      images: ['/data/hydrotek-lineardrainer-bars/RESHETKI-SLOT.png'],
      pdfURL: '/data/hydrotek-lineardrainer-bars/HYDROTEC-otvodnitelni-ylei-RESHETKI-SLOT.pdf'
    },
    {
      id: 'hydrotek-lineardrainer-top',
      pdf: true,
      title: 'Система решетки слот - top',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Линейно отводняване'],
      shortdescription: 'Патентована система за заключване на решетката без болтове',
      images: ['/data/hydrotek-lineardrainer-top/TOP.png'],
      pdfURL: '/data/hydrotek-lineardrainer-top/HYDROTEC-otvodnitelni-ylei-RESHETKI-SLOT.pdf'
    },
    {
      id: 'hydrotek-lineardrainer-mini',
      pdf: true,
      title: 'Система решетки слот - mini',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Линейно отводняване'],
      shortdescription: 'Отводняване на гаражи, пешеходни и велосипедни алеи, градски части, частни паркинги, вътрешно дворове и градини',
      images: ['/data/hydrotek-lineardrainer-mini/MINI.png'],
      pdfURL: '/data/hydrotek-lineardrainer-mini/HYDROTEC-otvodnitelni-ylei-RESHETKI-SLOT.pdf'
    },
    {
      id: 'hydrotek-lineardrainer-maxi',
      pdf: true,
      title: 'Система решетки слот - maxi',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Линейно отводняване'],
      shortdescription: 'Решетки: сферографитен чугун: надлъжни, напречни процепи и мрежеста',
      images: ['/data/hydrotek-lineardrainer-maxi/MAXI.png'],
      pdfURL: '/data/hydrotek-lineardrainer-maxi/HYDROTEC-otvodnitelni-ylei-RESHETKI-SLOT.pdf'
    },
    {
      id: 'hydrotek-lineardrainer-maxi-f1',
      pdf: true,
      title: 'Система решетки слот - maxi-f1',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Линейно отводняване'],
      shortdescription: 'Клас на натоварване: F 900',
      images: ['/data/hydrotek-lineardrainer-maxi-f1/MAXI-F1.png'],
      pdfURL: '/data/hydrotek-lineardrainer-maxi-f1/HYDROTEC-otvodnitelni-ylei-RESHETKI-SLOT.pdf'
    },
    {
      id: 'hydrotek-lineardrainer-maxi-pro',
      pdf: true,
      title: 'Система решетки слот - maxi pro',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Линейно отводняване'],
      shortdescription: 'Клас на натоварване: E 600, F 900',
      images: ['/data/hydrotek-lineardrainer-maxi-pro/MAXI-PRO.png'],
      pdfURL: '/data/hydrotek-lineardrainer-maxi-pro/HYDROTEC-otvodnitelni-ylei-RESHETKI-SLOT.pdf'
    },
    {
      id: 'hydrotek-lineardrainer-hydroline',
      pdf: true,
      title: 'Система решетки слот - hydroline',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Линейно отводняване'],
      shortdescription: 'Малка строителна височина, лесен монтаж',
      images: ['/data/hydrotek-lineardrainer-hydroline/HYDROline.png'],
      pdfURL: '/data/hydrotek-lineardrainer-hydroline/HYDROTEC-otvodnitelni-ylei-RESHETKI-SLOT.pdf'
    },
    {
      id: 'hydrotek-lineardrainer-hydroline-pro',
      pdf: true,
      title: 'Система решетки слот - hydroline-pro',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Линейно отводняване'],
      shortdescription: 'Изработен от алуминий, улеят с дължина от 3м е висок само 30мм',
      images: ['/data/hydrotek-lineardrainer-hydroline-pro/HYDROline-PRO.png'],
      pdfURL: '/data/hydrotek-lineardrainer-hydroline-pro/HYDROTEC-otvodnitelni-ylei-RESHETKI-SLOT.pdf'
    },
    {
      id: 'hydrotek-lineardrainer-hydroblock',
      pdf: true,
      title: 'Система решетки слот - hydroblock',
      categories: ['urban-infrastructure-parkinglots', 'urban-infrastructure-parks-and-gardens', 'urban-infrastructure-pedestrian-areas', 'urban-infrastructure-streets', 'transport-infrastructure-highway', 'transport-infrastructure-roads', 'shopping-areas-gas-stations-and-car-service', 'shopping-areas-malls-and-shopping-centers', 'shopping-areas-supermarkets', 'industrial-and-logistics-areas-airport', 'industrial-and-logistics-areas-dock', 'housing-construction-one-family-houses', 'housing-construction-housing-cooperatives'],
      categoriesNames: ['Хидротек - Линейно отводняване'],
      shortdescription: 'Специален дизайн на решетката за комфортно преминаване и в двете посоки: надлъжно и напречно',
      images: ['/data/hydrotek-lineardrainer-hydroblock/HYDROblock.png'],
      pdfURL: '/data/hydrotek-lineardrainer-hydroblock/HYDROTEC-otvodnitelni-ylei-RESHETKI-SLOT.pdf'
    },

  ]
}

const starter = (state = initialState, action) => {
  switch (action.type) {
    case StarterTypes.START_LOADING:
      return { ...state }
    case StarterTypes.STOP_LOADING:
      return { ...state }
    default:
      return state
  }
}

export default starter
