import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { startLoading } from '../../actions'
import Slider from 'react-slick'
import './mstyles.scss'

class HomeMobile extends Component {

  constructor(props) {
    super(props)
    this.state = {
      homeproducts: [
        { name: 'Пречиствателна станция', img: '/home/prechistvatelna-stancia.png', description: 'HYDROPRO предлага изработена механична конструкция на пречиствателна станция, предназначена за пречистване на битови отпадъчни води от източници като къщи, вили, къмпинги и малки населени места, които не са свързани към местната канализационна система.', link: '', pdf: false },
        { name: 'HYDROTANK', img: '/home/hydrotank.png', description: 'HYDROPRO предлага хоризонтални и вертикално стоящи резервоари, които се изработват от полиетилен или полипропилен.', link: '/solution/tanks', pdf: false },
        { name: 'Помпена станция', img: '/home/pompena-stancia.png', description: 'HYDROPRO помпените станции се използват за транспортиране води от едно ниво на друго ниво. Инсталират се непосредствено преди или след пречиствателната станция.', link: '', pdf: false },
        // { name: 'Пейка', img: '/home/bench.png', description: 'Пейките HYDROTEC са изработени от дърво (FSC 100%, необработени) или стомана и се предлагат в различни дизайни', link: '', pdf: false },
        { name: 'Каскадна шахта', img: '/home/kaskadna.png', description: 'При височина на пада от 0,5 м. до 6 м., се използват КАСКАДНИ ШАХТИ.', link: '/solution/shaft-cascading' },
        { name: 'HydroPRO Line', img: '/home/hydroline.png', description: 'HydroPRO Line е подходящ за събиране и използване на дъждовни води, като едновременно съчетава отводнител и колектор в едно съоръжение.', link: '/solution/hydrooil-lineardrainer', pdf: false },
        { name: 'Maxi 150', img: '/home/61540000-maxi-150-d400-r00045.png', description: 'Защита на ръбовете и решетка от чугун.', link: '/data/hydrotek-lineardrainer-maxi/HYDROTEC-otvodnitelni-ylei-RESHETKI-SLOT.pdf', pdf: true },
        // { name: 'Защита около дърветата', img: '/home/treeprotectors.png', description: 'Системата за защита на дърветата HYDROTEC гарантира ефективна защита на дърветата: защитава от запечатани подове, които влияят на подаването на въздух и вода - предпазва от повреда при сблъскване, която наранява ствола на дървото', link: '/data/hydrotek-centralparts-bars/HYDROTEC-CATALOG-TREE.pdf', pdf: true },
        { name: 'DSRS - Ревизионна', img: '/data/outflowpoints-revision/hydroprorevision1.png', description: 'Дъждоприемните и ревизионни шахти се използват за отвеждане на дъждовни води от пътната мрежа и има вътрешна стълба за безопасна инспекция.', link: '/solution/outflowpoints-revision', pdf: false },
        { name: 'Hydrooil Bypass', img: '/home/hydrooil-bypass.png', description: 'HYDROOIL BYPASS се използва за пречистване на дъждовна и отпадъчна вода, замърсена с петролни продукти, мазнини, масла, пясък, прах, кал и други.', link: '/solution/hydrooil-bypass', pdf: false },
        // { name: 'Кошче', img: '/home/bin.png', description: 'Кошчетата HYDROTEC са изработени от стоманена ламарина, горещо поцинкована.', link: '/data/hydrotek-centralparts-container/HYDROTEC-FAIL-1.pdf', pdf: true },
      ],
    }
  }

  startLoading() {
    this.props.dispatch(startLoading())
  }
  render() {

    const homeProducts = this.state.homeproducts.map((homeproducts, index) =>
      <div>
        {homeproducts.pdf ?
          <div className='item-wrap'><div className='item' id={'item-' + index}>
            <a href={homeproducts.link} target="_blank" rel="noopener noreferrer">
              <div className='box-info'>
                <img src={homeproducts.img} alt='' />
                <h3>{homeproducts.name}</h3>
                <p>{homeproducts.description}</p>
              </div>
            </a>
          </div></div>
          :
          <Link to={homeproducts.link} className='item-wrap'>
            <div className='item' id={'item-' + index}>
              <div className='box-info'>
                <img src={homeproducts.img} alt='' />
                <h3>{homeproducts.name}</h3>
                <p>{homeproducts.description}</p>
              </div>
            </div>
          </Link>
        }
      </div>)

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    };
    return (
      <div className='home-m'>
        <div className='container-m'>
          <div className='products-list-m'>
            <Slider {...settings}>
              {homeProducts}
            </Slider>
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(HomeMobile); 
